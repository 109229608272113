// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-endorsements-page-js": () => import("./../../../src/templates/endorsements-page.js" /* webpackChunkName: "component---src-templates-endorsements-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-languages-page-js": () => import("./../../../src/templates/languages-page.js" /* webpackChunkName: "component---src-templates-languages-page-js" */),
  "component---src-templates-meet-huge-page-js": () => import("./../../../src/templates/meet-huge-page.js" /* webpackChunkName: "component---src-templates-meet-huge-page-js" */),
  "component---src-templates-platform-page-js": () => import("./../../../src/templates/platform-page.js" /* webpackChunkName: "component---src-templates-platform-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-settings-page-js": () => import("./../../../src/templates/settings-page.js" /* webpackChunkName: "component---src-templates-settings-page-js" */),
  "component---src-templates-volunteer-page-js": () => import("./../../../src/templates/volunteer-page.js" /* webpackChunkName: "component---src-templates-volunteer-page-js" */)
}

